import React, { useEffect, useState } from "react";
import { useNotification } from "./NotificationContext";
import { Box, Snackbar, Alert } from "@mui/material";

const NotificationDisplay = () => {
  const { notifications, removeNotification, errors, removeError } =
    useNotification();
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentSeverity, setCurrentSeverity] = useState("success");

  useEffect(() => {
    if (notifications.length > 0) {
      setCurrentMessage(notifications[0]);
      setCurrentSeverity("success");
      setOpen(true);
    } else if (errors.length > 0) {
      setCurrentMessage(errors[0]);
      setCurrentSeverity("error");
      setOpen(true);
    }
  }, [notifications, errors]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    if (currentSeverity === "success") {
      removeNotification(0);
    } else {
      removeError(0);
    }
  };

  useEffect(() => {
    if (!open && notifications.length > 0) {
      setCurrentMessage(notifications[0]);
      setCurrentSeverity("success");
      setOpen(true);
    } else if (!open && errors.length > 0) {
      setCurrentMessage(errors[0]);
      setCurrentSeverity("error");
      setOpen(true);
    }
  }, [open, notifications, errors]);

  return (
    <div>
      {currentMessage && (
        <Box sx={{ width: 500 }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              marginTop: "60px",
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={currentMessage}
            key={currentMessage}
          >
            <Alert
              onClose={handleClose}
              severity={currentSeverity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {currentMessage} {/* Display the message */}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </div>
  );
};

export default NotificationDisplay;
