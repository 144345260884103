import React from "react";
import { Box, CircularProgress } from "@mui/material";

const CenteredCircularProgress = ({ sxProps }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        ...sxProps,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CenteredCircularProgress;
