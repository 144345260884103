import carboneSDK from "carbone-sdk-js";

function generateAndDownloadCarboneReport(token, template, data) {
  // template id
  const _template = template;

  let _data = data; //JSON.parse(data);

  const generateReport = async () => {
    // this is a test key from https://account.carbone.io/
    const _carboneService = carboneSDK(token);
    console.log(_carboneService);
    // Render the report from a template and a JSON Data
    const { content, name } = await _carboneService.render(_template, _data);

    // Create a download link for the report
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  };

  generateReport();
}

export { generateAndDownloadCarboneReport };
