import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import Workspace from "../PageStructure/Workspace";

import BookmarksIcon from "@mui/icons-material/Bookmarks";
import {
  fetchUserAccessibleCampuses,
  fetchCampusAssignedUsers,
  postNewTasks,
} from "../actions/api";

/**
 * Manage favorites
 * @param {Object} state - The event handler function.
 * @param {Object} chartObject - Object with complete chartview (including all charts, KPIs) definition.
 * @param {Array} yArrays - The array of y-axis data. One or more series.
 */
function ChartFavorites() {
  const { t } = useTranslation();
  const location = useLocation();
  const [newData, setNewData] = useState(location.state || {});
  // console.log(newData)
  //   const [task, setTask] = useState(() => {
  //     const saved = localStorage.getItem("selectedCampus"); // is array
  //     const obj = JSON.parse(saved);

  //     const newFavObject = {
  //         id: null,

  //     }

  //     return {
  //       title: "",
  //       due_date: "",
  //       priority: { text: "", api: "", key: "" },
  //       status: { text: "", api: "", key: "" },
  //       task_group: { text: "", api: "", key: "" },
  //       assigned_to: [],
  //       campus: obj === null || obj.length === 0 ? { id: "" } : obj[0], // preselect first element
  //     };
  //   });

  //   const [campuses, setCampuses] = useState(() => {
  //     const saved = localStorage.getItem("selectedCampus");
  //     return saved === null || saved.length === 0
  //       ? []
  //       : [JSON.parse(saved)].flat().map((camp) => {
  //           return { ...camp, label: camp.name };
  //         });
  //   });
  const [favorites, setFavorites] = useState([]);
  const [allowAssignedTo, setAllowAssignedTo] = useState(false);
  const [updateFailed, setupUpdateFailed] = useState(null);
  const navigate = useNavigate();

  // get all favorites for this user
  useEffect(() => {
    const fetchData = async () => {
      // TODO Write api function
      //const data = await fetchUserAccessibleFavorites();
      const data = [];
      setFavorites(data);
    };
    fetchData();
  }, []);

  // Handlers
  const handleSave = (e) => {
    // console.log("SAVE")

    // create object
    const toSave = {
      campusId: newData.sharedHeaderObject.chartViewData.campusId,
      targetId: newData.targetSelection.selectedTargetId,
      customerId: newData.sharedHeaderObject.chartViewData.customerId,
      selectedView: "tbd",
      selectedSubview: "tbd",
      dateRangeSelection: newData.dateRangeSelection,
      name: "tbd",
    };

    // call API
    // console.log(JSON.stringify(toSave))
    // remove state data
    setNewData({});
  };

  const addNewSection = () => {};

  return (
    <Workspace
      isLoading={false}
      title={t("CHART_FAVS")}
      titleIcon={<BookmarksIcon color="primary" sx={{ fontSize: 40 }} />}
      addTitleElementRightSide={null}
    >
      <Box sx={{ maxWidth: "800px" }}>
        {newData.targetSelection && (
          <Box sx={{ maxWidth: "800px" }}>
            {t("CHART_FAVS_ADD_NEW")}
            <div>
              {`${t("CHART_FAVS_ADD_NEW_CAMPUS")}: ${
                newData.sharedHeaderObject.chartViewData.campusId
              }`}
            </div>
            <div>
              {`${t("CHART_FAVS_ADD_NEW_TARGET")}: ${
                newData.targetSelection.selectionName
              }`}
            </div>
            <div>
              {`${t("CHART_FAVS_ADD_NEW_RANGE")}:  ${
                newData.dateRangeSelection.startDate
              } .. ${newData.dateRangeSelection.endDate}`}
            </div>
            <Button id="save-button" onClick={(e) => handleSave(e)}>
              <SaveAsOutlinedIcon />
            </Button>
          </Box>
        )}
      </Box>
      {updateFailed && (
        <Box sx={{ my: 2, color: "error.main" }}>
          {t("CREATION_FAILED_CHECK_ERRORS")}
        </Box>
      )}
    </Workspace>
  );
}

export default ChartFavorites;
