import React, { useEffect, useState } from "react";
import {
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import { useDrawerWidth } from "../../size";
import { styled } from "@mui/system";

import "react-multi-date-picker/styles/layouts/mobile.css";

// Define custom CSS styles using makeStyles
const useStyles = styled((theme) => ({
  calendarColor: {
    "--primary-font": "Urbanist, sans-serif",
    "--rmdp-primary-calendarColor": theme.palette.secondary.main,
    "--rmdp-secondary-calendarColor": theme.palette.secondary.light,
    "--rmdp-secondary-calendarColor-text": theme.palette.secondary.dark,
    "--rmdp-shadow-calendarColor": "#87ad92",
    "--rmdp-shadow-calendarColor-hover": theme.palette.info.main,
    "--rmdp-today-calendarColor": theme.palette.primary.main,
    "--rmdp-hover-calendarColor": "#2ecc40",
    "--rmdp-deselect-calendarColor": theme.palette.secondary.dark,
    // Add more custom properties as needed
    "& .rmdp-wrapper": {
      border: "1px solid var(--rmdp-secondary-calendarColor)",
      boxShadow: "0 0 5px var(--rmdp-secondary-calendarColor)",
    },
    "& .rmdp-range": {
      backgroundColor: "1px solid var(--rmdp-secondary-calendarColor)",
      color: "var(--rmdp-secondary-calendarColor-text)",
      boxShadow: "0 0 5px var(--rmdp-secondary-calendarColor)",
    },
    "& .rmdp-panel-body li": {
      backgroundColor: "var(--rmdp-primary-calendarColor)",
      boxShadow: "0 0 2px var(--rmdp-secondary-calendarColor)",
    },
    "& .rmdp-week-day": {
      color: "var(--rmdp-primary-calendarColor)",
    },
    "& .rmdp-day.rmdp-deactive": {
      color: "var(--rmdp-secondary-calendarColor)",
    },

    "& .rmdp-range-hover": {
      backgroundColor: "var(--rmdp-shadow-calendarColor-hover)",
      boxShadow: "0 0 3px var(--rmdp-shadow-calendarColor-hover)",
    },

    "& .rmdp-arrow": {
      border: "solid var(--rmdp-primary-calendarColor)",
      borderWidth: "0 2px 2px 0",
    },

    "& .rmdp-arrow-container:hover": {
      backgroundColor: "var(--rmdp-primary-calendarColor)",
      boxShadow: "0 0 3px var(--rmdp-secondary-calendarColor)",
    },

    "& .rmdp-panel-body::-webkit-scrollbar-thumb": {
      background: "var(--rmdp-primary-calendarColor)",
    },

    "& .rmdp-day.rmdp-today span": {
      backgroundColor: "var(--rmdp-today-calendarColor)",
    },

    "& .rmdp-rtl .rmdp-panel": {
      borderLeft: "unset",
      borderRight: "1px solid var(--rmdp-secondary-calendarColor)",
    },

    "& .rmdp-day.rmdp-selected span:not(.highlight)": {
      backgroundColor: "var(--rmdp-primary-calendarColor)",
      boxShadow: "0 0 3px var(--rmdp-shadow-calendarColor)",
    },

    "& .rmdp-day:not(.rmdp-day-hidden) span:hover": {
      backgroundColor: "var(--rmdp-hover-calendarColor) !important",
    },

    "& .b-deselect": {
      color: "var(--rmdp-deselect-calendarColor)",
      backgroundColor: "white",
    },

    "& .rmdp-action-button": {
      color: "var(--rmdp-primary-calendarColor)",
    },

    "& .rmdp-button:not(.rmdp-action-button)": {
      backgroundColor: "var(--rmdp-primary-calendarColor)",
    },

    "& .rmdp-button:not(.rmdp-action-button):hover": {
      backgroundColor: "var(--rmdp-deselect-calendarColor)",
    },

    // Add more nested styles as per the provided CSS
  },
}));

export const FullYearPlugin = ({ DatePicker, fullYear, setFullYear }) => {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth();

  // Full year not possible on small screens
  if (drawerWidth === 0) {
    setFullYear(false);
  }
  return (
    <div
      style={{
        paddingBottom: "0rem",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <IconButton
        aria-label="close"
        disabled={!DatePicker}
        onClick={() => DatePicker.closeCalendar()}
      >
        <CloseOutlinedIcon />
      </IconButton>

      {drawerWidth !== 0 && (
        <FormGroup style={{ paddingLeft: "1rem" }}>
          <FormControlLabel
            onChange={() => setFullYear(!fullYear)}
            control={<Checkbox />}
            label={t("FULL_YEAR")}
          />
        </FormGroup>
      )}
    </div>
  );
};

export const RangePicker = ({ disabled = false, span, setSpan }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [values, setValues] = useState([]);
  const [fullYear, setFullYear] = useState(false);
  const classes = useStyles();

  // State updates
  useEffect(() => {
    if (span.startDateTime) {
      const dateStart = new DateObject(span.startDateTime);
      const dateEnd = new DateObject(span.endDateTime);

      setValues([dateStart, dateEnd]);
    }
  }, [span]); // This should run once on component mount

  const handleOnClose = () => {
    if (values.length === 2) {
      // requested format:  2024-07-10T16:14:20:00Z
      // change time to end

      setSpan({
        startDate: values[0].format("YYYY-MM-DD"),
        startTime: values[0].format("00:00"),
        startDateTime: values[0].format("YYYY-MM-DD 00:00:00"), // 'YYYY-MM-DD HH:MM:SS'
        endDate: values[1].format("YYYY-MM-DD"),
        endTime: values[1].format("23:59"),
        endDateTime: values[1].format("YYYY-MM-DD 23:59:59"),
      });
    }

    if (values.length === 1) {
      // requested format:  2024-07-10T16:14:20:00Z
      // change time to end

      setSpan({
        startDate: values[0].format("YYYY-MM-DD"),
        startTime: values[0].format("00:00"),
        startDateTime: values[0].format("YYYY-MM-DD 00:00:00"),
        endDate: values[0].format("YYYY-MM-DD"),
        endTime: values[0].format("23:59"),
        endDateTime: values[0].format("YYYY-MM-DD 23:59:59"),
      });
    }
  };

  return (
    <div
      style={{
        pointerEvents: disabled === true ? "none" : null,
        opacity: disabled === true ? 0.5 : 1,
        alignSelf: "center",
        paddingBottom: "1rem",
      }}
    >
      {" "}
      <Calendar
        mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
          let props = {};

          props.style = {
            borderRadius: "3px",
            backgroundColor:
              date.month.index === currentMonth.index ? "#ccc" : "",
          };

          if (isSameDate(date, today)) props.style.color = "green";
          if (isSameDate(date, selectedDate))
            props.style = {
              ...props.style,
              color: "#0074d9",
              backgroundColor: "#a5a5a5",
              fontWeight: "bold",
              border: "1px solid #777",
            };

          return props;
        }}
        numberOfMonths={2}
        className={classes.calendarColor}
        value={values}
        onChange={setValues}
        range
        rangeHover
      ></Calendar>
      <DatePicker
        value={values}
        disabled={disabled}
        onClose={() => handleOnClose()}
        //className={drawerWidth === 0 ? "rmdp-mobile" : "custom-calendar"}
        className={classes.calendarColor}
        weekStartDayIndex={JSON.parse(t("WEEKSTARTINDEX"))}
        onChange={setValues}
        numberOfMonths={1}
        displayWeekNumbers
        weekNumber="W"
        fullYear={fullYear}
        showOtherDays
        range
        rangeHover
        plugins={[
          <FullYearPlugin
            position="top"
            fullYear={fullYear}
            setFullYear={setFullYear}
          />,
        ]}
        format={t("CALENDAR_FORMAT")}
        weekDays={JSON.parse(t("WEEKDAYS"))}
        months={JSON.parse(t("MONTHS"))}
        minDate="2020/01/01"
        dateSeparator=" - "
        maxDate={new DateObject().add(7, "days")}
        containerStyle={{ width: "220px" }}
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: "medium",
          paddingLeft: "0.5rem",
          width: "210px",
          backgroundColor: theme.palette.background.paper,
          //background: theme.palette.secondary.main,
          //color: theme.palette.secondary.contrastText,
          //border: '1px',
          //width: "100%",
          height: "36px",
          boxSizing: "border-box",
        }}
      />
    </div>
  );
};
// Set Date Time objects based on string
export const setDateTimeOnKey = (keyString) => {
  let range;
  const currentYear = new DateObject().year;
  const previousYear = currentYear - 1;

  const currentDate = new DateObject();
  const currentQuarter = Math.floor((currentDate.month + 3) / 3);
  const previousQuarter = currentQuarter - 1;

  switch (keyString) {
    case "this_day":
      range = [new DateObject().subtract(0, "days"), new DateObject()];
      break;
    case "this_week":
      range = [new DateObject().subtract(7, "days"), new DateObject()];
      break;
    case "this_month":
      range = [new DateObject().toFirstOfMonth(), new DateObject()];
      break;
    case "this_quarter": // TODO is wrong
      range = [
        new DateObject().set({
          year: currentYear,
          month: (currentQuarter - 1) * 3,
          date: 1,
        }),
        new DateObject().set({
          year: currentYear,
          month: currentQuarter * 3 - 1,
          date: 31,
        }),
      ];
      break;
    case "this_year":
      range = [
        new DateObject().set({ year: currentYear, month: 0, date: 1 }),
        new DateObject().set({ year: currentYear, month: 11, date: 31 }),
      ];
      break;
    case "last_day":
      range = [
        new DateObject().subtract(1, "days"),
        new DateObject().subtract(1, "days"),
      ];
      break;
    case "last_week":
      range = [
        new DateObject().subtract(14, "days"),
        new DateObject().subtract(7, "days"),
      ];
      break;
    case "last_month":
      range = [
        new DateObject().subtract(1, "month").toFirstOfMonth(),
        new DateObject().subtract(1, "month").toLastOfMonth(),
      ];
      break;
    case "last_quarter": // TODO is wrong
      range = [
        new DateObject().set({
          year: previousQuarter === 0 ? currentDate.year - 1 : currentDate.year,
          month: (previousQuarter - 1) * 3,
          date: 1,
        }),
        new DateObject().set({
          year: previousQuarter === 0 ? currentDate.year - 1 : currentDate.year,
          month: previousQuarter * 3 - 1,
          date: 31,
        }),
      ];
      break;
    case "last_year":
      range = [
        new DateObject().set({ year: previousYear, month: 0, date: 1 }),
        new DateObject().set({ year: previousYear, month: 11, date: 31 }),
      ];
      break;
    default:
      range = [new DateObject().subtract(7, "days"), new DateObject()];
  }

  return {
    startDate: range[0].format("YYYY-MM-DD"),
    startTime: range[0].format("00:00"),
    startDateTime: range[0].format("YYYY-MM-DD 00:00:00"),
    endDate: range[1].format("YYYY-MM-DD"),
    endTime: range[1].format("23:59"),
    endDateTime: range[1].format("YYYY-MM-DD 23:59:59"),
  };
};
