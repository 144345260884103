import React, { useState } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { alpha } from "@mui/material/styles";
import DynamicMarker from "./DynamicMarker";
import {
  Autocomplete,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { BORDER_RADIUS, HEADER_HEIGHT } from "../../constants";

const Map = ({ state, campuses, popupButtons }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedCampus, setSelectedCampus] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const options = campuses.map((campus) => campus.name);

  const handleChange = (e, newValue) => {
    setSelectedCampus(newValue);
  };
  //calc(100vh - ${HEADER_HEIGHT})`

  return (
    <div
      style={{
        position: "relative",
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        width: "100%",
      }}
    >
      <div
        style={{
          background: theme.palette.background.default,
          position: "absolute",
          top: isMobile ? 20 : isTablet ? "4.5rem" : "7rem", //see sidemenu
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: `${BORDER_RADIUS}px`,
          boxShadow: `-2px 5px 15px ${alpha(theme.palette.primary.dark, 0.5)}`,
          zIndex: 500,
        }}
      >
        <Autocomplete
          options={options}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label={t("SEARCH_CAMPUS")} />
          )}
          style={{ width: isMobile ? 300 : isTablet ? 500 : 600 }}
        />
      </div>
      <MapContainer
        center={state.position}
        zoom={state.zoom}
        zoomControl={false}
        id="workspaceMap"
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
        whenCreated={(map) => {
          map.createPane("tilePane");
          map.getPane("tilePane").style.zIndex = 200;
          map.createPane("overlayPane");
          map.getPane("overlayPane").style.zIndex = 400;
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          pane="tilePane"
        />
        <ZoomControl position="topright" />
        {campuses.map((campus, index) => (
          <DynamicMarker
            key={index}
            center={[campus.latitude, campus.longitude]}
            popupContent={
              <Card
                sx={{
                  maxWidth: 320,
                  overflow: "visible",
                  borderWidth: 0,
                  margin: 0,
                  padding: 0,
                  background: theme.palette.background.default,
                }}
                elevation={0}
              >
                <CardContent sx={{ margin: 0, padding: 0 }}>
                  <Typography variant="h6" component="div">
                    {campus.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {campus.description}
                  </Typography>
                  <Stack spacing={1}>
                    {popupButtons(campus.id, campus).map((element, index) => (
                      <Button
                        startIcon={element.icon}
                        component={Link}
                        onClick={element.func}
                        className="popup"
                        to={element.link}
                        fullWidth
                        variant="contained"
                        sx={{
                          mb: 1,
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                        }}
                        key={index}
                      >
                        {element.text}
                      </Button>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            }
            isTriggered={selectedCampus === campus.name}
          />
        ))}
      </MapContainer>
    </div>
  );
};

export default Map;
