import React, { useState } from "react";
import { IconButton, Stack, Paper, useTheme } from "@mui/material";
import { useDrawerWidth } from "../../size";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { createChartLink } from "../actions/api";
import { BORDER_RADIUS } from "../../constants";
import FavoriteDrawer from "../Generic/FavoriteDrawer";
import { alpha } from "@mui/material/styles";
import { useNotification } from "../Generic/NotificationContext";

export default function ChartButtons(props) {
  const { sharedHeaderObject, targetSelection, dateRangeSelection } = props;
  const drawerWidth = useDrawerWidth();
  const isEnabled =
    sharedHeaderObject.chartViewData &&
    targetSelection.selectedTargetId &&
    dateRangeSelection.startDate
      ? true
      : false;
  const theme = useTheme();
  const { addNotification, addError } = useNotification();
  // States
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [reload, setReload] = useState(false);

  // Handlers
  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleCreateLink = async () => {
    if (
      !targetSelection.selectedTargetId ||
      !sharedHeaderObject.chartViewData.campusId ||
      !dateRangeSelection.endDateTime
    ) {
      addNotification("Select target and date range before saving link.");
      return;
    }
    try {
      // TODO: Add dialog to change the name
      const dateStart = dateRangeSelection.startDateTime;
      const dateEnd = dateRangeSelection.endDateTime;
      const campus = sharedHeaderObject.chartHeaderObject.campusName;
      const topic =
        sharedHeaderObject.chartHeaderObject.selectedChartSubGroupLabel;
      const uniqueName = `${campus} / ${topic} \n ${new Date(dateStart)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, ".")}-${new Date(dateEnd)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, ".")}`;

      const linkData = {
        target_page: `${window.location.origin}/#/charts`, // Use window.location.origin
        page_data: {
          sharedHeaderObject,
          targetSelection,
          dateRangeSelection,
        },
        name: uniqueName,
      };

      const response = await createChartLink(linkData);
      if (response?.success) {
        const shareableLink = response.shareable_link;
        addNotification(`Link created successfully: ${shareableLink}`);
        // Optionally copy the link to clipboard
        navigator.clipboard.writeText(shareableLink).then(() => {
          addNotification(`Link copied to clipboard: ${shareableLink}`);
        });
        setReload(true);
        setTimeout(() => {
          setReload(false);
        }, 500);
      } else {
        addError(
          "Failed to create the link. A similar link may already exist."
        );
      }
    } catch (error) {
      console.error("Error creating chart link:", error);
      addError("Error occurred while creating the link.");
    }
  };

  // activate button only, of all data is valid
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: drawerWidth === 0 ? "space-between" : "left",
        flexWrap: "wrap",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          padding: "1rem",
          background: theme.palette.background.default,
          // transform: 'translateX(-50%)',
          borderRadius: `${BORDER_RADIUS}px`,
          border: `1px solid ${theme.palette.primary.dark}`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          boxShadow: `2px 0px 50px ${alpha(theme.palette.primary.dark, 0.1)}`,
        }}
        //   elevation={PAPER_ELEVATION}
        elevation={0}
      >
        <Stack direction="row" spacing={0.5}>
          <IconButton
            id="save-button"
            disabled={!isEnabled}
            onClick={handleCreateLink}
          >
            <FavoriteIcon />
          </IconButton>
          <IconButton
            id="favorite-button"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <BookmarksIcon />
          </IconButton>
          <FavoriteDrawer
            {...props}
            isOpen={drawerOpen}
            reload={reload}
            handleClose={handleClose}
          />
        </Stack>
      </Paper>
    </div>
  );
}
