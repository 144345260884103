import React, { useState, useEffect, useCallback } from "react";
import { useDrawerWidth } from "../../size";
import { DateObject, Calendar } from "react-multi-date-picker";
import {
  useTheme,
  Paper,
  Stack,
  useMediaQuery,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import RangeButton from "./RangeButton";
import {
  DATERANGES,
  BORDER_RADIUS,
  PAPER_ELEVATION,
  hotTranslate,
} from "../../constants";
import { setDateTimeOnKey } from "../Generic/RangePicker";
import i18next from "i18next";
import { styled } from "@mui/system";

// Define custom CSS styles using makeStyles
const StyledCalendar = styled(Calendar)(({ theme }) => ({
  // new style class for calendar

  "--primary-font": "Urbanist, sans-serif",
  "--rmdp-primary-calendarColor": theme.palette.secondary.main,
  "--rmdp-secondary-calendarColor": theme.palette.secondary.light,
  "--rmdp-secondary-calendarColor-text": theme.palette.secondary.dark,
  "--rmdp-shadow-calendarColor": theme.palette.info.light,
  "--rmdp-shadow-calendarColor-hover": theme.palette.info.light,
  "--rmdp-today-calendarColor": theme.palette.secondary.main,
  "--rmdp-hover-calendarColor": theme.palette.secondary.light,
  "--rmdp-deselect-calendarColor": theme.palette.secondary.dark,

  // Row with week days
  "& .rmdp-week-day": {
    // color: "var(--rmdp-primary-calendarColor)",
    color: theme.palette.text.primary,
    height: "28px !important",
    width: "28px !important",
  },

  //  Hover
  "& .rmdp-range-hover": {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    boxShadow: `0 0 3px ${theme.palette.secondary.light}`,
  },
  "& .rmdp-range": {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
    boxShadow: `0 0 3px ${theme.palette.secondary.light}`,
  },
  "&.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover": {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
  },

  // Arrows left/right
  "& .rmdp-arrow": {
    border: "solid var(--rmdp-primary-calendarColor)",
    borderWidth: "0 2px 2px 0",
  },
  "& .rmdp-arrow-container": {
    borderRadius: `${BORDER_RADIUS}px !important`,
    boxShadow: "0 0 3px var(--rmdp-secondary-calendarColor)",
  },

  "& .rmdp-arrow-container:hover": {
    borderRadius: `${BORDER_RADIUS}px !important`,
    background: theme.palette.secondary.light,
    // boxShadow: "0 0 3px var(--rmdp-secondary-calendarColor)",
  },

  "& .rmdp-panel-body::-webkit-scrollbar-thumb": {
    background: "var(--rmdp-primary-calendarColor)",
  },

  // Day definitions
  // all days
  "& .rmdp-day span": {
    // backgroundColor: "red !important",
    borderRadius: `${BORDER_RADIUS}px !important`,

    fontSize: "11px",
    left: "3px",
    top: "3px",
    right: "3px",
    bottom: "3px",
  },
  // today
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: `${theme.palette.primary.light} !important`,
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
  },
  // Week definitions
  // all days
  "& .rmdp-day": {
    // backgroundColor: "red !important",
    color: theme.palette.text.primary,
    // backgroundColor: `${theme.palette.primary.dark} !important`,
    height: "28px !important",
    width: "28px !important",
  },
}));
// Select date range
// Format object handler: { startDate: null, startTime: null, startDateTime: null, endDate: null, endTime: null, endDateTime: null }
const ChartDateRangeSelector = ({ objectHandler }) => {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth();
  const theme = useTheme();
  const [originalHovered, setOriginalHovered] = useState(false);
  const [newElementHovered, setNewElementHovered] = useState(false);
  const [values, setValues] = useState(() => {
    // str (haystack id)
    const saved = localStorage.getItem("selectedChartSpan");
    if (saved === null || saved.length === 0) {
      // Default: last seven days
      return [new DateObject().subtract(7, "days"), new DateObject()];
    } else {
      // Convert to value
      const obj = JSON.parse(saved);
      const dateStart = new DateObject(obj.startDateTime);
      const dateEnd = new DateObject(obj.endDateTime);
      return [dateStart, dateEnd];
    }
  });
  const [fullYear, setFullYear] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isVeryLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up("xl")
  );

  // Data states
  const [rangeType, setRangeType] = useState(() => {
    return hotTranslate(DATERANGES);
  });

  // Hot language change
  useEffect(() => {
    const languageChangedHandler = () => {
      setRangeType((prevRangeType) => hotTranslate(prevRangeType));
    };

    i18next.on("languageChanged", languageChangedHandler);

    return () => {
      i18next.off("languageChanged", languageChangedHandler);
    };
  }, []);

  const handleRange = (val, range) => {
    let updatedRange = rangeType.map((obj) => {
      if (obj.id === val) return { ...obj, selected: true };
      else return { ...obj, selected: false };
    });
    let thisElement = updatedRange.find((f) => f.selected === true);

    const newObject = setDateTimeOnKey(thisElement.value);
    // convert to value
    const dateStart = new DateObject(newObject.startDateTime);
    const dateEnd = new DateObject(newObject.endDateTime);
    setValues([dateStart, dateEnd]);
  };

  // Calculate span without state, vals: [DateTimeObject, ?DateTimeObject]
  const spanOnTheFly = useCallback(
    (vals) => {
      if (vals.length === 1 && vals[0] instanceof DateObject) {
        return {
          startDate: vals[0].format(t("RANGE_PICKER_FORMAT")),
          startTime: vals[0].format("00:00"),
          startDateTime: vals[0].format("YYYY-MM-DD 00:00:00"), // 'YYYY-MM-DD HH:MM:SS'
          endDate: vals[0].format(t("RANGE_PICKER_FORMAT")),
          endTime: vals[0].format("23:59"),
          endDateTime: vals[0].format("YYYY-MM-DD 23:59:59"),
        };
      }
      if (vals.length === 2 && vals[0] instanceof DateObject) {
        return {
          startDate: vals[0].format(t("RANGE_PICKER_FORMAT")),
          startTime: vals[0].format("00:00"),
          startDateTime: vals[0].format("YYYY-MM-DD 00:00:00"), // 'YYYY-MM-DD HH:MM:SS'
          endDate: vals[1].format(t("RANGE_PICKER_FORMAT")),
          endTime: vals[1].format("23:59"),
          endDateTime: vals[1].format("YYYY-MM-DD 23:59:59"),
        };
      }
      // default
      return {
        startDate: null,
        startTime: null,
        startDateTime: null,
        endDate: null,
        endTime: null,
        endDateTime: null,
      };
    },
    [t]
  );

  const stableObjectHandler = useCallback(
    (spanObj) => {
      objectHandler(spanObj);
    },
    [objectHandler]
  );

  const showRangeStr = () => {
    const range = spanOnTheFly(values);
    return range.startDate
      ? `${range.startDate} - ${range.endDate}`
      : t("SELECT_DATE_RANGE");
  };

  // Update parents / storage with object
  useEffect(() => {
    const spanObj = spanOnTheFly(values);
    localStorage.setItem("selectedChartSpan", JSON.stringify(spanObj));
    stableObjectHandler(spanObj);
  }, [values, stableObjectHandler, spanOnTheFly]);

  // Hover functions
  const handleMouseEnterOriginal = () => {
    setOriginalHovered(true);
  };

  const handleMouseLeaveOriginal = () => {
    if (!newElementHovered) {
      setOriginalHovered(false);
    }
  };

  const handleMouseEnterNewElement = () => {
    setNewElementHovered(true);
  };

  const handleMouseLeaveNewElement = () => {
    setNewElementHovered(false);
    if (!originalHovered) {
      setOriginalHovered(false);
    }
  };

  // Selection element
  const selectorElement = () => {
    return (
      <Stack
        direction="row"
        spacing={0.5}
        style={{
          paddingBottom: "1rem",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          style={{
            paddingBottom: "1rem",
          }}
        >
          {rangeType.slice(0, 5).map((obj, index) => (
            <RangeButton
              key={`range1-${index}`}
              allObj={rangeType}
              singleObj={obj}
              handleRange={handleRange}
            />
          ))}
          {drawerWidth !== 0 && (
            <FormGroup style={{ paddingLeft: "0px" }}>
              <FormControlLabel
                onChange={() => setFullYear(!fullYear)}
                control={<Checkbox />}
                label={t("FULL_YEAR")}
              />
            </FormGroup>
          )}
        </Stack>
        <div
          style={{
            paddingBottom: "0rem",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* <IconButton
                        aria-label="close"
                        //onClick={() => DatePicker.closeCalendar()}
                    >
                        <CloseOutlinedIcon />
                    </IconButton> */}
        </div>
        <StyledCalendar
          numberOfMonths={1}
          weekStartDayIndex={JSON.parse(t("WEEKSTARTINDEX"))}
          weekNumber="W"
          weekDays={JSON.parse(t("WEEKDAYS"))}
          format={t("CALENDAR_FORMAT")}
          months={JSON.parse(t("MONTHS"))}
          minDate="2020/01/01"
          dateSeparator=" - "
          maxDate={new DateObject().add(7, "days")}
          showOtherDays
          value={values}
          onChange={setValues}
          range={true}
          multiple={false}
          fullYear={fullYear}
          rangeHover
        />
        <Stack
          direction="column"
          spacing={1}
          style={{
            paddingLeft: "1rem",
            paddingBottom: "1rem",
          }}
        >
          {rangeType.slice(5).map((obj, index) => (
            <RangeButton
              key={`range2-${index}`}
              allObj={rangeType}
              singleObj={obj}
              handleRange={handleRange}
            />
          ))}
        </Stack>
      </Stack>
    );
  };

  if (isVeryLargeScreen) {
    return (
      <Paper
        sx={{
          width: isMobile ? "100%" : isTablet ? "100%" : null,
          padding: "1rem",
          height: "auto", // 350
          borderRadius: `${BORDER_RADIUS}px`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
        }}
        id="range-select-paper"
        elevation={PAPER_ELEVATION}
      >
        <Stack
          direction="row"
          spacing={0.5}
          style={{
            paddingBottom: "1rem",
          }}
        >
          <Stack
            direction="column"
            key="stack-1"
            spacing={1}
            style={{
              paddingBottom: "1rem",
            }}
          >
            {rangeType.slice(0, 5).map((obj, index) => (
              <RangeButton
                allObj={rangeType}
                singleObj={obj}
                handleRange={handleRange}
                key={obj.id}
              />
            ))}
            {drawerWidth !== 0 && (
              <FormGroup style={{ paddingLeft: "0px" }}>
                <FormControlLabel
                  onChange={() => setFullYear(!fullYear)}
                  control={<Checkbox />}
                  label={t("FULL_YEAR")}
                />
              </FormGroup>
            )}
          </Stack>
          <div
            style={{
              paddingBottom: "0rem",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {/* <IconButton
                        aria-label="close"
                        //onClick={() => DatePicker.closeCalendar()}
                    >
                        <CloseOutlinedIcon />
                    </IconButton> */}
          </div>
          <StyledCalendar
            numberOfMonths={1}
            weekStartDayIndex={JSON.parse(t("WEEKSTARTINDEX"))}
            weekNumber="W"
            weekDays={JSON.parse(t("WEEKDAYS"))}
            format={t("CALENDAR_FORMAT")}
            months={JSON.parse(t("MONTHS"))}
            minDate="2020/01/01"
            dateSeparator=" - "
            maxDate={new DateObject().add(7, "days")}
            showOtherDays
            value={values}
            onChange={setValues}
            range={true}
            multiple={false}
            fullYear={fullYear}
            rangeHover
          />
          <Stack
            direction="column"
            key="stack-2"
            spacing={1}
            style={{
              paddingLeft: "1rem",
              paddingBottom: "1rem",
            }}
          >
            {rangeType.slice(5).map((obj, index) => (
              <RangeButton
                allObj={rangeType}
                singleObj={obj}
                handleRange={handleRange}
                key={obj.id}
              />
            ))}
          </Stack>
        </Stack>
        <div
          style={{
            paddingBottom: "0rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
          }}
        >
          <TextField
            id="outlined-basic"
            value={showRangeStr()}
            InputProps={{
              readOnly: true, // Prevent user input
              sx: {
                "& .MuiInputBase-input": {
                  color: theme.palette.text.secondary,

                  height: "100%",
                  padding: "16.5px 14px",
                  fontSize: "1.2rem",
                  textAlign: "center",
                },
              },
            }}
            sx={{
              width: "100%",
              height: "60px",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.text.secondary,
                },
              },
              "& .MuiInputLabel-root": {
                color: theme.palette.text.secondary,
              },
            }}
            variant="outlined"
          />
        </div>
      </Paper>
    );
  } else {
    return (
      <Paper
        sx={{
          width: "100%",
          padding: "1rem",
          height: "auto", // 350
          borderRadius: `${BORDER_RADIUS}px`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
        }}
        id="range-select-paper-small"
        elevation={PAPER_ELEVATION}
      >
        <TextField
          id="outlined-basic"
          value={showRangeStr()}
          onMouseEnter={handleMouseEnterOriginal}
          onMouseLeave={handleMouseLeaveOriginal}
          variant="outlined"
          InputProps={{
            readOnly: true, // Prevent user input
            sx: {
              "& .MuiInputBase-input": {
                color: theme.palette.text.secondary,

                height: "100%",
                padding: "16.5px 14px",
                fontSize: "1.2rem",
                textAlign: "center",
              },
            },
          }}
          sx={{
            width: "100%",
            height: "60px",

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.text.secondary,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.secondary.main,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.secondary.main,
              },
            },
            "& .MuiInputLabel-root": {
              color: theme.palette.text.secondary,
            },
          }}
        />
        {(originalHovered || newElementHovered) && (
          <div
            onMouseEnter={handleMouseEnterNewElement}
            onMouseLeave={handleMouseLeaveNewElement}
            style={{
              paddingTop: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectorElement()}
          </div>
        )}
      </Paper>
    );
  }
};

export default ChartDateRangeSelector;
