import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  ListItem,
  Card,
  CardContent,
  List,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import Workspace from "../PageStructure/Workspace";
import { deleteTask } from "../actions/api";
import { PAPER_ELEVATION } from "../../constants";

/**
 * Generic delete component. Asks for confirmation. Data is received via useLocation hook
 * @returns {JSX.Element} The rendered Delete component
 *  */

function Delete() {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // The api func and arguments must be provided
  // apiFunc: string name of function
  // argList: array
  // data: array
  // dataKey: string
  // targetLink: string with target to navigate back
  const { apiFunc, argList, data, dataKey, targetLink } = location.state;

  const handleConfirmDelete = async () => {
    setConfirmOpen(false);
    let response = null;
    if (argList.length > 0) {
      switch (apiFunc) {
        case "deleteTask":
          for (const arg of argList) {
            const res = await deleteTask(arg);
            if (!res) {
              response = false;
              break;
            }
          }
          if (response === null) response = true;
          break;
        default:
          console.error("Unknown api func");
          response = false;
      }
    }

    if (response) {
      navigate(targetLink);
    } else {
      setShowMessage(true);
    }
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    navigate("/tasks");
  };

  return (
    <Workspace
      isLoading={false}
      titleIcon={<DeleteForeverIcon color="warning" sx={{ fontSize: 40 }} />}
      title={t("Delete")}
    >
      <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          {t("DELETE_ENTRIES_INTRO")}
        </Typography>
        <Card elevation={PAPER_ELEVATION}>
          <CardContent>
            <List>
              {data?.map((item) => (
                <ListItem
                  key={item[dataKey]}
                  disablePadding
                  sx={{
                    py: 1,
                    bgcolor: "transparent", // This line removes the background
                  }}
                >
                  <Typography variant="body1">{`${item[dataKey]}`}</Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            startIcon={<CloseIcon />}
          >
            {t("RETURN")}
          </Button>
          <div style={{ display: "inline-block" }}>
            <Button
              className="workspace"
              variant="contained"
              color="error"
              onClick={() => setConfirmOpen(true)}
              startIcon={<DeleteForeverIcon />}
            >
              {t("CONFIRM")}
            </Button>
          </div>
        </Box>

        {showMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {t("DELETE_FAILED")}
            <br />
            {t("CONSOLE_INSTRUCTIONS")}
          </Alert>
        )}

        <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
          <DialogTitle>
            <WarningIcon sx={{ mr: 1 }} />
            {t("CONFIRM_DELETE_TITLE")}
          </DialogTitle>
          <DialogContent>
            <Typography>{t("CONFIRM_DELETE_MESSAGE")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmOpen(false)}>{t("CANCEL")}</Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmDelete}
            >
              {t("CONFIRM_DELETE")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Workspace>
  );
}

export default Delete;
