import React, { useEffect, useState, useCallback } from "react";
import ChartBlock from "./ChartBlock";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { CircularProgress, Box, Typography } from "@mui/material";

import { useDrawerWidth } from "../../size";
import {
  fetchChartDescription,
  fetchChartTimeSeriesData,
} from "../actions/api";
import { getChartBlockSizes } from "./chartFuncs";
import { SIDEBAR_WIDTH_SM } from "../../constants";

// Debounce function
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

/**
 * Component defining the view section resolution of a chart page.
 * @param {Object} sharedHeaderObject - Object with campus and chart selection data.
 * @param {Object} targetSelection - Object with target selection data
 * @param {Object} dateRangeSelection - Object with date range data.
 */
const ChartGraphWorkspace = ({
  sharedHeaderObject,
  targetSelection,
  dateRangeSelection,
}) => {
  const { selectedTargetId, selectedTargetCharts } = targetSelection;
  const { startDateTime, endDateTime } = dateRangeSelection;
  const { chartViewData, targetData } = sharedHeaderObject;
  const { customerID, campusId } = chartViewData;

  const idContainer = "graphContainer";

  const drawerWidth = useDrawerWidth(); // if 0 => mobile
  const boxWidth = drawerWidth === 0 ? "100%" : "100%";

  const [dataSets, setDataSets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [blockSize, setBlockSize] = useState(() => {
    // []

    if (selectedTargetCharts && selectedTargetCharts.length > 0 && dataReady) {
      return selectedTargetCharts
        .sort((a, b) => a.index - b.index)
        .map((chart) =>
          getChartBlockSizes(idContainer, drawerWidth, chart.dataSeries)
        );
    } else {
      return []; // Return an empty array if no charts are selected
    }
  });
  const [chartDescriptions, setChartDescriptions] = useState([]); // TODO
  // console.log("test", sharedHeaderObject, targetSelection, dateRangeSelection)
  const handleResize = useCallback(() => {
    if (!selectedTargetCharts) return;
    if (!dataReady) return;
    // if idContainer is not yet set, return []
    try {
      const sizes = selectedTargetCharts
        .sort((a, b) => a.index - b.index)
        .map((chart) =>
          getChartBlockSizes(idContainer, drawerWidth, chart.dataSeries)
        );

      setBlockSize(sizes);
    } catch (error) {
      console.error("Check the sizeDefiningId", error);
    }
  }, [idContainer, drawerWidth, selectedTargetCharts, dataReady]);

  // Resize all components
  useEffect(() => {
    handleResize(); // Initial call to set sizes

    const debouncedResize = debounce(handleResize, 100);
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, [handleResize]);

  // Load chart templates
  useEffect(() => {
    if (customerID && campusId) {
      const fetchData = async () => {
        const newDescriptions = await fetchChartDescription(
          customerID,
          campusId
        );
        setChartDescriptions(newDescriptions);
      };

      fetchData();
    }
  }, [customerID, campusId]);

  // Store data sets
  useEffect(() => {
    if (chartViewData.length === 0) {
      setDataSets([]);
    }
  }, [chartViewData]);

  useEffect(() => {
    // Data is in right format
    const fetchData = async (
      _idList,
      _modelist,
      _startDateTime,
      _endDateTime
    ) => {
      try {
        const data = await fetchChartTimeSeriesData(
          _idList,
          _modelist,
          _startDateTime,
          _endDateTime,
          "uplot"
        );

        return data;
      } catch (error) {
        console.error("ChartGraphWorkspace: Error fetching data:", error);
        return [];
      }
    };

    const updateDataSets = async (_charts, _start, _end) => {
      const promises = _charts.map(async (_chart) => {
        // _chart.dataSeries is a list with 0..n entries
        if (_chart.dataSeries) {
          const pointList = _chart.dataSeries
            .map((series) => series.points)
            .flat();
          const modeList = _chart.dataSeries
            .map((series) => series.hisMode)
            .flat();
          return await fetchData(pointList, modeList, _start, _end);
        } else return [];
      });

      try {
        setDataReady(false);
        setLoading(true);

        const newDataSetValues = await Promise.all(promises);
        // combine all data sets
        setDataSets(newDataSetValues);
      } catch (error) {
        console.error("Error updating data sets:", error);
      } finally {
        setLoading(false);
        setDataReady(true);
      }
    };

    // Verify, if selectedTargetId is within targetData. If not reset data
    const foundIndex = targetData.findIndex((k) => k.id === selectedTargetId);
    if (foundIndex === -1) {
      setDataSets([]);
    } else if (selectedTargetCharts && startDateTime) {
      // clear datasets first
      setDataSets([]);
      updateDataSets(selectedTargetCharts, startDateTime, endDateTime);
    } else {
      setDataSets([]);
    }
  }, [
    selectedTargetCharts,
    selectedTargetId,
    startDateTime,
    endDateTime,
    targetData,
  ]);

  // TODO Translatios
  // Early returns (test all object)
  if (
    !sharedHeaderObject.targetData &&
    !sharedHeaderObject.chartHeaderObject &&
    !sharedHeaderObject.chartViewData
  ) {
    // console.log("early return - sharedHeaderObject - null or undefined")
    return null;
  }
  if (
    sharedHeaderObject.targetData.length === 0 ||
    Object.entries(sharedHeaderObject.chartHeaderObject).length === 0 ||
    Object.entries(sharedHeaderObject.chartViewData).length === 0
  ) {
    // console.log("early return - sharedHeaderObject - empty objects", sharedHeaderObject)
    return null;
  }

  if (
    selectedTargetId === null ||
    startDateTime === null ||
    dataSets.length === 0
  ) {
    // console.log("early return - no target or no id or no data",selectedTargetId, startDateTime, dataSets)
    return null;
  }

  const Loading = () => {
    <Box
      id={idContainer}
      sx={{
        width: boxWidth,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
      }}
    >
      <CircularProgress color="primary" />
      <Typography variant="body1" sx={{ mt: 2 }}>
        Loading all data sets...
      </Typography>
    </Box>;
  };

  // Early return for loading state, important width
  if (loading) {
    return (
      <Loading />
      // <Box
      //   id={idContainer}
      //   sx={{
      //     width: boxWidth,
      //     display: "flex",
      //     alignItems: "center",
      //     justifyContent: "center",
      //     flexDirection: "column",
      //     padding: "16px",
      //   }}
      // >
      //   <CircularProgress color="primary" />
      //   <Typography variant="body1" sx={{ mt: 2 }}>
      //     Loading all data sets...
      //   </Typography>
      // </Box>
    );
  }

  // Main rendering logic
  return (
    <div
      id={idContainer}
      style={{
        width: boxWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "left",
      }}
    >
      {dataReady &&
        selectedTargetCharts
          ?.sort((a, b) => a.index - b.index)
          .map((chart, index) => {
            const uniqueKey = chart.spec
              ? `div_hist_plot_${chart.spec}`
              : `div_hist_plot_${index}`;

            if (
              chart.type === "oxoia.charts::HistorianChart" &&
              chart.dataSeries &&
              dataSets[index].length > 0 &&
              blockSize.length > 0 &&
              Object.keys(blockSize[0]).length > 0 // div with id === {idContainer} must be loaded and block calculations finished
            ) {
              return (
                <ChartBlock
                  key={uniqueKey}
                  loadingState={loading || dataSets.length === 0 ? 0 : 1} // show skeleton
                  blockSize={blockSize[index]}
                  chartKey={`chartblock-children-${uniqueKey}`}
                  title={chart.title}
                  chartSpec={chart.spec}
                  chartDefs={chart.dataSeries}
                  chartData={dataSets[index]}
                  chartDescriptions={chartDescriptions}
                  parentContainerId={idContainer}
                  divKey={`${idContainer}-${index}`}
                  kpiDefs={sharedHeaderObject.chartViewData.kpis}
                  kpiData={["45%", 5, "OK"]} // playground, needs api call
                  chartViewAttr={sharedHeaderObject}
                  id={`blockkey-${index}`}
                />
              );
            }
            return <Loading key={uniqueKey} />;
            // return <div key={uniqueKey}><CircularProgress color="primary" /></div>;
          })}
      {/* Special block for mobile devices, do not delete */}
      <div
        style={{ height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px" }}
      ></div>
    </div>
  );
};
export default ChartGraphWorkspace;
